import React, { useState, useEffect } from 'react';
import './App.css';
import Header from './Header.js';
import ImagesDisplay from './ImageDisplay';
import MusicPlayer from './MusicPlayer';

const images = [
  'irene2.jpg',
  'irene4.jpg',
  'irene5.jpg',
  'irene6.jpg',
  'irene7.jpg',
  'irene8.jpg',
  'irene9.jpg',
  'irene10.jpg',
  'irene11.jpg',
  'irene12.jpg',
  'irene13.jpg',
  'irene14.jpg',
  'irene15.jpg'
];

const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

const App = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);
  const [copyMessage, setCopyMessage] = useState('EJ1RbQZs1r1eTAnMTVfgGekgWt6nbHjNGqcn8prxpump');

  const handleImageClick = (image) => {
    setPreviewImage(image);
  };

  const handleClosePreview = () => {
    setPreviewImage(null);
  };

  const handleCopy = () => {
    navigator.clipboard.writeText('EJ1RbQZs1r1eTAnMTVfgGekgWt6nbHjNGqcn8prxpump');
    setCopyMessage('Copied!');
    setTimeout(() => setCopyMessage('EJ1RbQZs1r1eTAnMTVfgGekgWt6nbHjNGqcn8prxpump'), 2000);
  };

  useEffect(() => {
    const createBubbles = () => {
      const bubblesContainer = document.querySelector('.bubbles');
      if (bubblesContainer) {
        const shuffledImages = shuffleArray([...images]); // Shuffle the images array
        const isMobile = window.innerWidth <= 768;
        const numberOfBubbles = isMobile ? Math.floor(shuffledImages.length / 16) : shuffledImages.length / 4; // Adjust the number of bubbles

        for (let i = 0; i < numberOfBubbles; i++) {
          const image = shuffledImages[Math.floor(Math.random() * shuffledImages.length)];
          const bubble = document.createElement('div');
          bubble.className = 'bubble';
          bubble.style.backgroundImage = `url(${process.env.PUBLIC_URL}/${image})`;
          bubble.style.width = `${Math.random() * 100 + 50}px`;
          bubble.style.height = bubble.style.width;
          bubble.style.left = `${Math.random() * 100}%`;
          bubble.style.animationDelay = `${Math.random() * 5}s`;
          bubble.addEventListener('click', () => handleImageClick(image));
          bubblesContainer.appendChild(bubble);
        }
      }
    };

    const createSparkles = () => {
      const sparklesContainer = document.querySelector('.sparkles');
      if (sparklesContainer) {
        for (let i = 0; i < 100; i++) {
          const sparkle = document.createElement('div');
          sparkle.className = 'sparkle';
          sparkle.style.top = `${Math.random() * 100}%`;
          sparkle.style.left = `${Math.random() * 100}%`;
          sparkle.style.animationDelay = `${Math.random() * 2}s`;
          sparklesContainer.appendChild(sparkle);
        }
      }
    };

    createBubbles();
    createSparkles();

    const handleFirstClick = () => {
      setIsPlaying(true);
      window.removeEventListener('click', handleFirstClick);
    };

    window.addEventListener('click', handleFirstClick);

    return () => {
      window.removeEventListener('click', handleFirstClick);
    };
  }, []);

  return (
    <div className="App">
      <div className="sparkles"></div>
      <div className="bubbles"></div>
      <MusicPlayer isPlaying={isPlaying} />
      <img src={`${process.env.PUBLIC_URL}/irene1.jpg`} alt="Model" className="centered-image" />
      <Header />
      <ImagesDisplay />
      <div className="contract-address-title">$IRENE ASIAN MOTHER</div>
      <div className="contract-address-email">Email: AsianMotherTeam@proton.me</div>
      <div className="contract-address" onClick={handleCopy}>{copyMessage}</div>
      {previewImage && (
        <div className="image-preview" onClick={handleClosePreview}>
          <img src={`${process.env.PUBLIC_URL}/${previewImage}`} alt="Preview" />
        </div>
        
      )}
    </div>
  );
}

export default App;