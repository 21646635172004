import React, { useState } from 'react';
import './Header.css';

const Header = ({ isMuted, toggleMute }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="horizontal-nav">
      <div className="burger-menu" onClick={toggleMenu}>
        <div className={`bar ${isOpen ? 'open' : ''}`}></div>
        <div className={`bar ${isOpen ? 'open' : ''}`}></div>
        <div className={`bar ${isOpen ? 'open' : ''}`}></div>
      </div>
      <div className={`nav-links ${isOpen ? 'open' : ''}`}>
        
        <a href="https://raydium.io/swap/?outputMint=sol&inputMint=EJ1RbQZs1r1eTAnMTVfgGekgWt6nbHjNGqcn8prxpump">Buy $IRENE</a>
        <a href="https://www.dextools.io/app/en/solana/pair-explorer/CojYEost8kvQRkv3Kqjy55ZnBvzhmXyEBfRYNuGmse53?t=1717749578527" target="_blank" rel="noopener noreferrer">
          Dextools
        </a>
        <a href="https://dexscreener.com/solana/cojyeost8kvqrkv3kqjy55znbvzhmxyebfrynugmse53" target="_blank" rel="noopener noreferrer">
          Screener
        </a>
        <a href="https://t.me/Asianmother" target="_blank" rel="noopener noreferrer">
          Telegram
        </a>
        <a href="https://x.com/ireneonsol" target="_blank" rel="noopener noreferrer">
          X
        </a>
        
       
       
      </div>
    </nav>
  );
}

export default Header;